
import Vue, { PropType } from 'vue';

const removeHashes = (themeData: AestheticTheme): string => {
  const backgroundImageUrl = themeData.images?.[0]?.largeImageUrl || 'none';
  const logoImageUrl = themeData.secondaryImages?.[0]?.largeImageUrl || 'none';
  const encodedBackgroundImageUrl = encodeURIComponent(backgroundImageUrl);
  const encodedLogoImageUrl = encodeURIComponent(logoImageUrl);
  return `&mt=${themeData.metaTheme}&bc=${themeData.backgroundColor}&bi=${encodedBackgroundImageUrl}&li=${encodedLogoImageUrl}&ac=${themeData.accentColor}&pfn=${themeData.primaryFontName}&hfn=${themeData.headerFontName}&pfv=${themeData.primaryFontVariant}&hfv=${themeData.headerFontVariant}&pfs=${themeData.primaryFontScale}&hfs=${themeData.headerFontScale}&hft=${themeData.uppercaseHeaderFont}&cs=${themeData.cardStyle}&at=${themeData.ambientText}`.replaceAll(
    '#',
    '%23'
  );
};

export default Vue.extend({
  props: {
    themeData: {
      type: Object as PropType<AestheticTheme>,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    iframeSrc: string;
    previewDebounce: number;
    iframeWidth: number;
    showThemePreviewDialog: boolean;
  } {
    return {
      iframeSrc: this.themeData ? `https://sitch.app/themepreview?themeprev=true${removeHashes(this.themeData)}` : '',
      previewDebounce: 0,
      iframeWidth: 400,
      showThemePreviewDialog: false,
    };
  },
  watch: {
    themeData: {
      handler() {
        clearInterval(this.previewDebounce);
        this.previewDebounce = window.setTimeout(() => {
          this.iframeSrc = this.themeData ? `https://sitch.app/themepreview?themeprev=true${removeHashes(this.themeData)}` : '';
        }, 1000);
      },
      deep: true,
    },
  },
  methods: {
    sizeDown() {
      if (this.iframeWidth > 200) {
        this.iframeWidth -= 100;
      }
    },
    sizeUp() {
      if (this.iframeWidth < 700) {
        this.iframeWidth += 100;
      }
    },
  },
});
