
import Vue from 'vue';
import { allFontImageNames } from '@/all-font-images';

const defaultThemeBackgroundColor = '#ededf7';

// These must be lowercase.
const lightBackgroundColors = [
  '#ffebee',
  '#fce4ec',
  '#f3e5f5',
  '#ede7f6',
  '#e8eaf6',
  '#e3f2fd',
  '#e1f5fe',
  '#e0f7fa',
  '#e0f2f1',
  '#e8f5e9',
  '#f1f8e9',
  '#fffde7',
  '#fff8e1',
  '#fff3e0',
  '#fbe9e7',
  '#fafafa',
  '#eceff1',
  '#f5f5f5',
  '#ffcdd2',
  '#f8bbd0',
  '#e1bee7',
  '#d1c4e9',
  '#c5cae9',
  '#bbdefb',
  '#b3e5fc',
  '#b2ebf2',
  '#b2dfdb',
  '#c8e6c9',
  '#dcedc8',
  '#fff9c4',
  '#ffecb3',
  '#ffe0b2',
  '#ffccbc',
  '#d7ccc8',
  '#cfd8dc',
  '#fffde7',
  '#fff59d',
  '#fdb515',
  '#f9a825',
  defaultThemeBackgroundColor,
];

export default Vue.extend({
  props: {
    fontName: {
      type: String,
      default: '',
    },
    fontVariant: {
      type: String,
      default: '',
    },
    isOnLightOrDarkThemeBackground: {
      type: Boolean,
      default: false,
    },
    themeBackground: {
      type: String,
      default: '',
    },
  },
  data(): {
    publicPath: string;
  } {
    return {
      publicPath: process.env.BASE_URL || '',
    };
  },
  computed: {
    makeFontPreviewsWhite(): boolean {
      const isLightTheme =
        lightBackgroundColors.includes(this.themeBackground?.toLowerCase()) || this.themeBackground?.toLowerCase().includes(`linear-gradient(to right, ${defaultThemeBackgroundColor},`);
      return !isLightTheme && !this.isOnLightOrDarkThemeBackground;
    },
  },
  methods: {
    fontImageExists(name: string) {
      return allFontImageNames.includes(name.replace(/\s/g, ''));
    },
  },
});
