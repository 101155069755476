// Images in the assets folder are courtesy of https://github.com/getstencil/GoogleWebFonts-FontFamilyPreviewImages.
export const allFontImageNames = [
  'ABeeZee',
  'Abel',
  'AbhayaLibre',
  'AbrilFatface',
  'Aclonica',
  'Acme',
  'Actor',
  'Adamina',
  'AdventPro',
  'AguafinaScript',
  'AkayaKanadaka',
  'AkayaTelivigala',
  'Akronim',
  'Aladin',
  'Alata',
  'Alatsi',
  'Aldrich',
  'Alef',
  'Alegreya',
  'AlegreyaSans',
  'AlegreyaSansSC',
  'AlegreyaSC',
  'Aleo',
  'AlexBrush',
  'AlfaSlabOne',
  'Alice',
  'Alike',
  'AlikeAngular',
  'Allan',
  'Allerta',
  'AllertaStencil',
  'Allison',
  'Allura',
  'Almarai',
  'Almendra',
  'AlmendraDisplay',
  'AlmendraSC',
  'AlumniSans',
  'AlumniSansInlineOne',
  'Amarante',
  'Amaranth',
  'AmaticaSC',
  'AmaticSC',
  'Amethysta',
  'Amiko',
  'Amiri',
  'Amita',
  'Anaheim',
  'Andada',
  'AndadaPro',
  'Andika',
  'AndikaNewBasic',
  'AnekBangla',
  'AnekDevanagari',
  'AnekGujarati',
  'AnekGurmukhi',
  'AnekKannada',
  'AnekLatin',
  'AnekMalayalam',
  'AnekOdia',
  'AnekTamil',
  'AnekTelugu',
  'Angkor',
  'AnnieUseYourTelescope',
  'AnonymousPro',
  'Antic',
  'AnticDidone',
  'AnticSlab',
  'Anton',
  'Antonio',
  'Anybody',
  'Arapey',
  'Arbutus',
  'ArbutusSlab',
  'ArchitectsDaughter',
  'Archivo',
  'ArchivoBlack',
  'ArchivoNarrow',
  'ArefRuqaa',
  'AreYouSerious',
  'ArimaMadurai',
  'Arimo',
  'Arizonia',
  'Armata',
  'Arsenal',
  'Artifika',
  'Arvo',
  'Arya',
  'Asap',
  'AsapCondensed',
  'Asar',
  'Asset',
  'Assistant',
  'Astloch',
  'Asul',
  'Athiti',
  'AtkinsonHyperlegible',
  'Atma',
  'AtomicAge',
  'Aubrey',
  'Audiowide',
  'AutourOne',
  'Average',
  'AverageSans',
  'AveriaGruesaLibre',
  'AveriaLibre',
  'AveriaSansLibre',
  'AveriaSerifLibre',
  'AzeretMono',
  'B612',
  'B612Mono',
  'Babylonica',
  'BadScript',
  'Bahiana',
  'Bahianita',
  'BaiJamjuree',
  'BakbakOne',
  'Ballet',
  'Baloo',
  'Baloo2',
  'BalooBhai',
  'BalooBhai2',
  'BalooBhaijaan',
  'BalooBhaijaan2',
  'BalooBhaina',
  'BalooBhaina2',
  'BalooChettan',
  'BalooChettan2',
  'BalooDa',
  'BalooDa2',
  'BalooPaaji',
  'BalooPaaji2',
  'BalooTamma',
  'BalooTamma2',
  'BalooTammudu',
  'BalooTammudu2',
  'BalooThambi',
  'BalooThambi2',
  'BalsamiqSans',
  'Balthazar',
  'Bangers',
  'Barlow',
  'BarlowCondensed',
  'BarlowSemiCondensed',
  'Barriecito',
  'Barrio',
  'Basic',
  'Baskervville',
  'Battambang',
  'Baumans',
  'Bayon',
  'BeauRivage',
  'BebasNeue',
  'Belgrano',
  'Bellefair',
  'Belleza',
  'Bellota',
  'BellotaText',
  'BenchNine',
  'Benne',
  'Bentham',
  'BerkshireSwash',
  'Besley',
  'BethEllen',
  'Bevan',
  'BeVietnam',
  'BeVietnamPro',
  'BhuTukaExpandedOne',
  'BigelowRules',
  'BigshotOne',
  'BigShouldersDisplay',
  'BigShouldersInlineDisplay',
  'BigShouldersInlineText',
  'BigShouldersStencilDisplay',
  'BigShouldersStencilText',
  'BigShouldersText',
  'Bilbo',
  'BilboSwashCaps',
  'BioRhyme',
  'BioRhymeExpanded',
  'Birthstone',
  'BirthstoneBounce',
  'Biryani',
  'Bitter',
  'BIZUDGothic',
  'BIZUDMincho',
  'BIZUDPGothic',
  'BIZUDPMincho',
  'BlackAndWhitePicture',
  'BlackHanSans',
  'BlackOpsOne',
  'Blinker',
  'BodoniModa',
  'Bokor',
  'BonaNova',
  'Bonbon',
  'BonheurRoyale',
  'Boogaloo',
  'BowlbyOne',
  'BowlbyOneSC',
  'Brawler',
  'BreeSerif',
  'Brygada1918',
  'BubblegumSans',
  'BubblerOne',
  'Buenard',
  'Bungee',
  'BungeeHairline',
  'BungeeInline',
  'BungeeOutline',
  'BungeeShade',
  'Butcherman',
  'ButterflyKids',
  'Cabin',
  'CabinCondensed',
  'CabinSketch',
  'CaesarDressing',
  'Cagliostro',
  'Cairo',
  'Caladea',
  'Calistoga',
  'Calligraffitti',
  'Cambay',
  'Cambo',
  'Candal',
  'Cantarell',
  'CantataOne',
  'CantoraOne',
  'Capriola',
  'Caramel',
  'Carattere',
  'Cardo',
  'Carme',
  'CarroisGothic',
  'CarroisGothicSC',
  'CarterOne',
  'Castoro',
  'Catamaran',
  'Caudex',
  'Caveat',
  'CaveatBrush',
  'CedarvilleCursive',
  'CevicheOne',
  'ChakraPetch',
  'Changa',
  'ChangaOne',
  'Chango',
  'Charm',
  'Charmonman',
  'Chathura',
  'ChauPhilomeneOne',
  'ChelaOne',
  'ChelseaMarket',
  'Chenla',
  'Cherish',
  'CherryCreamSoda',
  'CherrySwash',
  'Chewy',
  'Chicle',
  'Chilanka',
  'Chivo',
  'Chonburi',
  'Cinzel',
  'CinzelDecorative',
  'ClickerScript',
  'Coda',
  'Codystar',
  'Coiny',
  'Combo',
  'Comfortaa',
  'Comforter',
  'ComforterBrush',
  'ComicNeue',
  'ComingSoon',
  'Commissioner',
  'ConcertOne',
  'Condiment',
  'Content',
  'ContrailOne',
  'Convergence',
  'Cookie',
  'Copse',
  'Corben',
  'Corinthia',
  'Cormorant',
  'CormorantGaramond',
  'CormorantInfant',
  'CormorantSC',
  'CormorantUnicase',
  'CormorantUpright',
  'Courgette',
  'CourierPrime',
  'Cousine',
  'Coustard',
  'CoveredByYourGrace',
  'CraftyGirls',
  'Creepster',
  'CreteRound',
  'CrimsonPro',
  'CrimsonText',
  'CroissantOne',
  'Crushed',
  'Cuprum',
  'CuteFont',
  'Cutive',
  'CutiveMono',
  'Damion',
  'DancingScript',
  'Dangrek',
  'DarkerGrotesque',
  'DavidLibre',
  'DawningofaNewDay',
  'DaysOne',
  'Dekko',
  'DelaGothicOne',
  'Delius',
  'DeliusSwashCaps',
  'DeliusUnicase',
  'DellaRespira',
  'DenkOne',
  'Devonshire',
  'Dhurjati',
  'DidactGothic',
  'Diplomata',
  'DiplomataSC',
  'DMMono',
  'DMSans',
  'DMSerifDisplay',
  'DMSerifText',
  'DoHyeon',
  'Dokdo',
  'Domine',
  'DonegalOne',
  'Dongle',
  'DoppioOne',
  'Dorsa',
  'Dosis',
  'DotGothic16',
  'DroidSans',
  'DroidSansMono',
  'DroidSerif',
  'DrSugiyama',
  'DuruSans',
  'Dynalight',
  'EagleLake',
  'EastSeaDokdo',
  'Eater',
  'EBGaramond',
  'Economica',
  'Eczar',
  'EkMukta',
  'Electrolize',
  'ElMessiri',
  'Elsie',
  'ElsieSwashCaps',
  'EmblemaOne',
  'EmilysCandy',
  'EncodeSans',
  'EncodeSansCondensed',
  'EncodeSansExpanded',
  'EncodeSansSC',
  'EncodeSansSemiCondensed',
  'EncodeSansSemiExpanded',
  'Engagement',
  'Englebert',
  'Enriqueta',
  'Ephesis',
  'Epilogue',
  'EricaOne',
  'Esteban',
  'Estonia',
  'EuphoriaScript',
  'Ewert',
  'Exo',
  'Exo2',
  'ExpletusSans',
  'Explora',
  'Fahkwang',
  'FamiljenGrotesk',
  'FanwoodText',
  'Farro',
  'Farsan',
  'Fascinate',
  'FascinateInline',
  'FasterOne',
  'Fasthand',
  'FaunaOne',
  'Faustina',
  'Federant',
  'Federo',
  'Felipa',
  'Fenix',
  'Festive',
  'FingerPaint',
  'FiraCode',
  'FiraMono',
  'FiraSans',
  'FiraSansCondensed',
  'FiraSansExtraCondensed',
  'FjallaOne',
  'FjordOne',
  'Flamenco',
  'Flavors',
  'FleurDeLeah',
  'FlowBlock',
  'FlowCircular',
  'FlowRounded',
  'Fondamento',
  'FontdinerSwanky',
  'Forum',
  'FrancoisOne',
  'FrankRuhlLibre',
  'Fraunces',
  'FreckleFace',
  'FrederickatheGreat',
  'Fredoka',
  'FredokaOne',
  'Freehand',
  'Fresca',
  'Frijole',
  'Fruktur',
  'FugazOne',
  'Fuggles',
  'FuzzyBubbles',
  'Gabriela',
  'Gaegu',
  'Gafata',
  'Galada',
  'Galdeano',
  'Galindo',
  'GamjaFlower',
  'Gayathri',
  'Gelasio',
  'GemunuLibre',
  'Genos',
  'GentiumBasic',
  'GentiumBookBasic',
  'Geo',
  'Georama',
  'Geostar',
  'GeostarFill',
  'GermaniaOne',
  'GFSDidot',
  'GFSNeohellenic',
  'GideonRoman',
  'Gidugu',
  'GildaDisplay',
  'Girassol',
  'GiveYouGlory',
  'GlassAntiqua',
  'Glegoo',
  'GloriaHallelujah',
  'Glory',
  'Gluten',
  'GoblinOne',
  'GochiHand',
  'Goldman',
  'Gorditas',
  'GothicA1',
  'Gotu',
  'GoudyBookletter1911',
  'GowunBatang',
  'GowunDodum',
  'Graduate',
  'GrandHotel',
  'Grandstander',
  'GrapeNuts',
  'GravitasOne',
  'GreatVibes',
  'GrechenFuemen',
  'Grenze',
  'GrenzeGotisch',
  'GreyQo',
  'Griffy',
  'Gruppo',
  'Gudea',
  'Gugi',
  'Gupter',
  'Gurajada',
  'Gwendolyn',
  'Habibi',
  'HachiMaruPop',
  'Hahmlet',
  'Halant',
  'HammersmithOne',
  'Hanalei',
  'HanaleiFill',
  'Handlee',
  'Hanuman',
  'HappyMonkey',
  'Harmattan',
  'HeadlandOne',
  'Heebo',
  'HennyPenny',
  'HeptaSlab',
  'HerrVonMuellerhoff',
  'HiMelody',
  'HinaMincho',
  'Hind',
  'HindGuntur',
  'HindMadurai',
  'HindSiliguri',
  'HindVadodara',
  'HoltwoodOneSC',
  'HomemadeApple',
  'Homenaje',
  'Hubballi',
  'Hurricane',
  'IbarraRealNova',
  'IBMPlexMono',
  'IBMPlexSans',
  'IBMPlexSansArabic',
  'IBMPlexSansCondensed',
  'IBMPlexSansDevanagari',
  'IBMPlexSansHebrew',
  'IBMPlexSansKR',
  'IBMPlexSansThai',
  'IBMPlexSansThaiLooped',
  'IBMPlexSerif',
  'Iceberg',
  'Iceland',
  'Imbue',
  'IMFellDoublePica',
  'IMFellDoublePicaSC',
  'IMFellDWPica',
  'IMFellDWPicaSC',
  'IMFellEnglish',
  'IMFellEnglishSC',
  'IMFellFrenchCanon',
  'IMFellFrenchCanonSC',
  'IMFellGreatPrimer',
  'IMFellGreatPrimerSC',
  'ImperialScript',
  'Imprima',
  'Inconsolata',
  'Inder',
  'IndieFlower',
  'IngridDarling',
  'Inika',
  'InknutAntiqua',
  'InriaSans',
  'InriaSerif',
  'Inspiration',
  'Inter',
  'IrishGrover',
  'IslandMoments',
  'IstokWeb',
  'Italiana',
  'Italianno',
  'Itim',
  'JacquesFrancois',
  'JacquesFrancoisShadow',
  'Jaldi',
  'JetBrainsMono',
  'JimNightshade',
  'JockeyOne',
  'JollyLodger',
  'Jomhuria',
  'Jomolhari',
  'JosefinSans',
  'JosefinSlab',
  'Jost',
  'JotiOne',
  'Jua',
  'Judson',
  'Julee',
  'JuliusSansOne',
  'Junge',
  'Jura',
  'JustAnotherHand',
  'JustMeAgainDownHere',
  'K2D',
  'Kadwa',
  'KaiseiDecol',
  'KaiseiHarunoUmi',
  'KaiseiOpti',
  'KaiseiTokumin',
  'Kalam',
  'Kameron',
  'Kanit',
  'Kantumruy',
  'Karantina',
  'Karla',
  'Karma',
  'Katibeh',
  'KaushanScript',
  'Kavivanar',
  'Kavoon',
  'KdamThmor',
  'KeaniaOne',
  'KellySlab',
  'Kenia',
  'Khand',
  'Khmer',
  'Khula',
  'Kings',
  'KirangHaerang',
  'KiteOne',
  'KiwiMaru',
  'KleeOne',
  'Knewave',
  'Kodchasan',
  'KoHo',
  'KohSantepheap',
  'KolkerBrush',
  'Kosugi',
  'KosugiMaru',
  'KottaOne',
  'Koulen',
  'Kranky',
  'Kreon',
  'Kristi',
  'KronaOne',
  'Krub',
  'Kufam',
  'KulimPark',
  'KumarOne',
  'KumarOneOutline',
  'KumbhSans',
  'Kurale',
  'LaBelleAurore',
  'Lacquer',
  'Laila',
  'LakkiReddy',
  'Lalezar',
  'Lancelot',
  'Langar',
  'Lateef',
  'Lato',
  'LavishlyYours',
  'LeagueGothic',
  'LeagueScript',
  'LeagueSpartan',
  'LeckerliOne',
  'Ledger',
  'Lekton',
  'Lemon',
  'Lemonada',
  'Lexend',
  'LexendDeca',
  'LexendExa',
  'LexendGiga',
  'LexendMega',
  'LexendPeta',
  'LexendTera',
  'LexendZetta',
  'LibreBarcode128',
  'LibreBarcode128Text',
  'LibreBarcode39',
  'LibreBarcode39Extended',
  'LibreBarcode39ExtendedText',
  'LibreBarcode39Text',
  'LibreBarcodeEAN13Text',
  'LibreBaskerville',
  'LibreCaslonDisplay',
  'LibreCaslonText',
  'LibreFranklin',
  'Licorice',
  'LifeSavers',
  'LilitaOne',
  'LilyScriptOne',
  'Limelight',
  'LindenHill',
  'Literata',
  'LiuJianMaoCao',
  'Livvic',
  'Lobster',
  'LobsterTwo',
  'LondrinaOutline',
  'LondrinaShadow',
  'LondrinaSketch',
  'LondrinaSolid',
  'LongCang',
  'Lora',
  'LovedbytheKing',
  'LoveLight',
  'LoversQuarrel',
  'LoveYaLikeASister',
  'LuckiestGuy',
  'Lusitana',
  'Lustria',
  'LuxuriousRoman',
  'LuxuriousScript',
  'Macondo',
  'MacondoSwashCaps',
  'Mada',
  'Magra',
  'MaidenOrange',
  'Maitree',
  'MajorMonoDisplay',
  'Mako',
  'Mali',
  'Mallanna',
  'Mandali',
  'Manjari',
  'Manrope',
  'Mansalva',
  'Manuale',
  'Marcellus',
  'MarcellusSC',
  'MarckScript',
  'Margarine',
  'MarkaziText',
  'MarkoOne',
  'Marmelad',
  'Martel',
  'MartelSans',
  'Marvel',
  'MaShanZheng',
  'Mate',
  'MateSC',
  'MavenPro',
  'McLaren',
  'MeaCulpa',
  'Meddon',
  'MedievalSharp',
  'MedulaOne',
  'MeeraInimai',
  'Megrim',
  'MeieScript',
  'MeowScript',
  'Merienda',
  'MeriendaOne',
  'Merriweather',
  'MerriweatherSans',
  'Metal',
  'MetalMania',
  'Metamorphous',
  'Metrophobic',
  'Michroma',
  'Milonga',
  'Miltonian',
  'MiltonianTattoo',
  'Mina',
  'Miniver',
  'MiriamLibre',
  'Mirza',
  'MissFajardose',
  'Mitr',
  'MochiyPopOne',
  'MochiyPopPOne',
  'Modak',
  'ModernAntiqua',
  'Mogra',
  'Mohave',
  'Molengo',
  'Monda',
  'Monofett',
  'Monoton',
  'MonsieurLaDoulaise',
  'Montaga',
  'MontaguSlab',
  'MonteCarlo',
  'Montez',
  'Montserrat',
  'MontserratAlternates',
  'MontserratSubrayada',
  'MooLahLah',
  'MoonDance',
  'Moul',
  'Moulpali',
  'MountainsofChristmas',
  'MouseMemoirs',
  'MPLUS1',
  'MPLUS1Code',
  'MPLUS1p',
  'MPLUS2',
  'MPLUSCodeLatin',
  'MPLUSRounded1c',
  'MrBedfort',
  'MrDafoe',
  'MrDeHaviland',
  'MrsSaintDelafield',
  'MrsSheppards',
  'Mukta',
  'MuktaMahee',
  'MuktaMalar',
  'MuktaVaani',
  'Muli',
  'Mulish',
  'Murecho',
  'MuseoModerno',
  'MysteryQuest',
  'NanumBrushScript',
  'NanumGothic',
  'NanumGothicCoding',
  'NanumMyeongjo',
  'NanumPenScript',
  'Neonderthaw',
  'NerkoOne',
  'Neucha',
  'Neuton',
  'NewRocker',
  'NewsCycle',
  'Newsreader',
  'NewTegomin',
  'Niconne',
  'Niramit',
  'NixieOne',
  'Nobile',
  'Nokora',
  'Norican',
  'Nosifer',
  'Notable',
  'NothingYouCouldDo',
  'NoticiaText',
  'NotoKufiArabic',
  'NotoMusic',
  'NotoNaskhArabic',
  'NotoNastaliqUrdu',
  'NotoRashiHebrew',
  'NotoSans',
  'NotoSansAdlam',
  'NotoSansAdlamUnjoined',
  'NotoSansAnatolianHieroglyphs',
  'NotoSansArabic',
  'NotoSansArmenian',
  'NotoSansAvestan',
  'NotoSansBalinese',
  'NotoSansBamum',
  'NotoSansBassaVah',
  'NotoSansBatak',
  'NotoSansBengali',
  'NotoSansBhaiksuki',
  'NotoSansBrahmi',
  'NotoSansBuginese',
  'NotoSansBuhid',
  'NotoSansCanadianAboriginal',
  'NotoSansCarian',
  'NotoSansCaucasianAlbanian',
  'NotoSansChakma',
  'NotoSansCham',
  'NotoSansCherokee',
  'NotoSansCoptic',
  'NotoSansCuneiform',
  'NotoSansCypriot',
  'NotoSansDeseret',
  'NotoSansDevanagari',
  'NotoSansDisplay',
  'NotoSansDuployan',
  'NotoSansEgyptianHieroglyphs',
  'NotoSansElbasan',
  'NotoSansElymaic',
  'NotoSansGeorgian',
  'NotoSansGlagolitic',
  'NotoSansGothic',
  'NotoSansGrantha',
  'NotoSansGujarati',
  'NotoSansGunjalaGondi',
  'NotoSansGurmukhi',
  'NotoSansHanifiRohingya',
  'NotoSansHanunoo',
  'NotoSansHatran',
  'NotoSansHebrew',
  'NotoSansHK',
  'NotoSansImperialAramaic',
  'NotoSansIndicSiyaqNumbers',
  'NotoSansInscriptionalPahlavi',
  'NotoSansInscriptionalParthian',
  'NotoSansJavanese',
  'NotoSansJP',
  'NotoSansKaithi',
  'NotoSansKannada',
  'NotoSansKayahLi',
  'NotoSansKharoshthi',
  'NotoSansKhmer',
  'NotoSansKhojki',
  'NotoSansKhudawadi',
  'NotoSansKR',
  'NotoSansLao',
  'NotoSansLepcha',
  'NotoSansLimbu',
  'NotoSansLinearA',
  'NotoSansLinearB',
  'NotoSansLisu',
  'NotoSansLycian',
  'NotoSansLydian',
  'NotoSansMahajani',
  'NotoSansMalayalam',
  'NotoSansMandaic',
  'NotoSansManichaean',
  'NotoSansMarchen',
  'NotoSansMasaramGondi',
  'NotoSansMath',
  'NotoSansMayanNumerals',
  'NotoSansMedefaidrin',
  'NotoSansMeeteiMayek',
  'NotoSansMeroitic',
  'NotoSansMiao',
  'NotoSansModi',
  'NotoSansMongolian',
  'NotoSansMono',
  'NotoSansMro',
  'NotoSansMultani',
  'NotoSansMyanmar',
  'NotoSansNabataean',
  'NotoSansNewa',
  'NotoSansNewTaiLue',
  'NotoSansNKo',
  'NotoSansNushu',
  'NotoSansOgham',
  'NotoSansOlChiki',
  'NotoSansOldHungarian',
  'NotoSansOldItalic',
  'NotoSansOldNorthArabian',
  'NotoSansOldPermic',
  'NotoSansOldPersian',
  'NotoSansOldSogdian',
  'NotoSansOldSouthArabian',
  'NotoSansOldTurkic',
  'NotoSansOriya',
  'NotoSansOsage',
  'NotoSansOsmanya',
  'NotoSansPahawhHmong',
  'NotoSansPalmyrene',
  'NotoSansPauCinHau',
  'NotoSansPhagsPa',
  'NotoSansPhoenician',
  'NotoSansPsalterPahlavi',
  'NotoSansRejang',
  'NotoSansRunic',
  'NotoSansSamaritan',
  'NotoSansSaurashtra',
  'NotoSansSC',
  'NotoSansSharada',
  'NotoSansShavian',
  'NotoSansSiddham',
  'NotoSansSinhala',
  'NotoSansSogdian',
  'NotoSansSoraSompeng',
  'NotoSansSoyombo',
  'NotoSansSundanese',
  'NotoSansSylotiNagri',
  'NotoSansSymbols',
  'NotoSansSymbols2',
  'NotoSansSyriac',
  'NotoSansTagalog',
  'NotoSansTagbanwa',
  'NotoSansTaiLe',
  'NotoSansTaiTham',
  'NotoSansTaiViet',
  'NotoSansTakri',
  'NotoSansTamil',
  'NotoSansTamilSupplement',
  'NotoSansTC',
  'NotoSansTelugu',
  'NotoSansThaana',
  'NotoSansThai',
  'NotoSansThaiLooped',
  'NotoSansTifinagh',
  'NotoSansTirhuta',
  'NotoSansUgaritic',
  'NotoSansVai',
  'NotoSansWancho',
  'NotoSansWarangCiti',
  'NotoSansYi',
  'NotoSansZanabazarSquare',
  'NotoSerif',
  'NotoSerifAhom',
  'NotoSerifArmenian',
  'NotoSerifBalinese',
  'NotoSerifBengali',
  'NotoSerifDevanagari',
  'NotoSerifDisplay',
  'NotoSerifDogra',
  'NotoSerifEthiopic',
  'NotoSerifGeorgian',
  'NotoSerifGrantha',
  'NotoSerifGujarati',
  'NotoSerifGurmukhi',
  'NotoSerifHebrew',
  'NotoSerifJP',
  'NotoSerifKannada',
  'NotoSerifKhmer',
  'NotoSerifKR',
  'NotoSerifLao',
  'NotoSerifMalayalam',
  'NotoSerifMyanmar',
  'NotoSerifNyiakengPuachueHmong',
  'NotoSerifSC',
  'NotoSerifSinhala',
  'NotoSerifTamil',
  'NotoSerifTangut',
  'NotoSerifTC',
  'NotoSerifTelugu',
  'NotoSerifThai',
  'NotoSerifTibetan',
  'NotoSerifYezidi',
  'NotoTraditionalNushu',
  'NovaCut',
  'NovaFlat',
  'NovaMono',
  'NovaOval',
  'NovaRound',
  'NovaScript',
  'NovaSlim',
  'NovaSquare',
  'NTR',
  'Numans',
  'Nunito',
  'NunitoSans',
  'OdibeeSans',
  'OdorMeanChey',
  'Offside',
  'Oi',
  'Oldenburg',
  'OldStandardTT',
  'Ole',
  'OleoScript',
  'OleoScriptSwashCaps',
  'OoohBaby',
  'OpenSans',
  'Oranienbaum',
  'Orbitron',
  'Oregano',
  'OrelegaOne',
  'Orienta',
  'OriginalSurfer',
  'Oswald',
  'OtomanopeeOne',
  'Outfit',
  'Overlock',
  'OverlockSC',
  'Overpass',
  'OverpassMono',
  'OvertheRainbow',
  'Ovo',
  'Oxanium',
  'Oxygen',
  'OxygenMono',
  'Pacifico',
  'Padauk',
  'Palanquin',
  'PalanquinDark',
  'PaletteMosaic',
  'Pangolin',
  'Paprika',
  'Parisienne',
  'PasseroOne',
  'PassionOne',
  'PassionsConflict',
  'PathwayGothicOne',
  'PatrickHand',
  'PatrickHandSC',
  'Pattaya',
  'PatuaOne',
  'Pavanam',
  'PaytoneOne',
  'Peddana',
  'Peralta',
  'PermanentMarker',
  'Petemoss',
  'PetitFormalScript',
  'Petrona',
  'Philosopher',
  'Piazzolla',
  'Piedra',
  'PinyonScript',
  'PirataOne',
  'Plaster',
  'Play',
  'Playball',
  'PlayfairDisplay',
  'PlayfairDisplaySC',
  'Podkova',
  'PoiretOne',
  'PollerOne',
  'Poly',
  'Pompiere',
  'PontanoSans',
  'PoorStory',
  'Poppins',
  'PortLligatSans',
  'PortLligatSlab',
  'PottaOne',
  'PragatiNarrow',
  'Praise',
  'Prata',
  'Preahvihear',
  'PressStart2P',
  'Pridi',
  'PrincessSofia',
  'Prociono',
  'Prompt',
  'ProstoOne',
  'ProzaLibre',
  'PTMono',
  'PTSans',
  'PTSansCaption',
  'PTSansNarrow',
  'PTSerif',
  'PTSerifCaption',
  'PublicSans',
  'PuppiesPlay',
  'Puritan',
  'PurplePurse',
  'Pushster',
  'Qahiri',
  'Quando',
  'Quantico',
  'Quattrocento',
  'QuattrocentoSans',
  'Questrial',
  'Quicksand',
  'Quintessential',
  'Qwigley',
  'QwitcherGrypen',
  'RacingSansOne',
  'Radley',
  'Rajdhani',
  'Rakkas',
  'Raleway',
  'RalewayDots',
  'Ramabhadra',
  'Ramaraja',
  'Rambla',
  'RammettoOne',
  'RampartOne',
  'Ranchers',
  'Rancho',
  'Ranga',
  'Rasa',
  'Rationale',
  'RaviPrakash',
  'ReadexPro',
  'Recursive',
  'Redacted',
  'RedactedScript',
  'RedHatDisplay',
  'RedHatMono',
  'RedHatText',
  'Redressed',
  'RedRose',
  'ReemKufi',
  'ReenieBeanie',
  'ReggaeOne',
  'Revalia',
  'RhodiumLibre',
  'Ribeye',
  'RibeyeMarrow',
  'Righteous',
  'Risque',
  'RoadRage',
  'Roboto',
  'RobotoCondensed',
  'RobotoMono',
  'RobotoSerif',
  'RobotoSlab',
  'Rochester',
  'Rock3D',
  'RocknRollOne',
  'RockSalt',
  'Rokkitt',
  'Romanesco',
  'RopaSans',
  'Rosario',
  'Rosarivo',
  'RougeScript',
  'Rowdies',
  'RozhaOne',
  'Rubik',
  'RubikBeastly',
  'RubikBubbles',
  'RubikGlitch',
  'RubikMicrobe',
  'RubikMonoOne',
  'RubikMoonrocks',
  'RubikOne',
  'RubikPuddles',
  'RubikWetPaint',
  'Ruda',
  'Rufina',
  'RugeBoogie',
  'Ruluko',
  'RumRaisin',
  'RuslanDisplay',
  'RussoOne',
  'Ruthie',
  'Rye',
  'Sacramento',
  'Sahitya',
  'Sail',
  'Saira',
  'SairaCondensed',
  'SairaExtraCondensed',
  'SairaSemiCondensed',
  'SairaStencilOne',
  'Salsa',
  'Sanchez',
  'Sancreek',
  'Sansita',
  'SansitaOne',
  'SansitaSwashed',
  'Sarabun',
  'Sarala',
  'Sarina',
  'Sarpanch',
  'SassyFrass',
  'Satisfy',
  'SawarabiGothic',
  'SawarabiMincho',
  'Scada',
  'Scheherazade',
  'ScheherazadeNew',
  'Schoolbell',
  'ScopeOne',
  'SeaweedScript',
  'SecularOne',
  'SedgwickAve',
  'SedgwickAveDisplay',
  'Sen',
  'SendFlowers',
  'Sevillana',
  'SeymourOne',
  'ShadowsIntoLight',
  'ShadowsIntoLightTwo',
  'Shalimar',
  'Shanti',
  'Share',
  'ShareTech',
  'ShareTechMono',
  'ShipporiAntique',
  'ShipporiAntiqueB1',
  'ShipporiMincho',
  'Shizuru',
  'Shojumaru',
  'ShortStack',
  'Shrikhand',
  'Siemreap',
  'SigmarOne',
  'Signika',
  'SignikaNegative',
  'Simonetta',
  'SingleDay',
  'Sintony',
  'SirinStencil',
  'SixCaps',
  'Skranji',
  'Slabo13px',
  'Slabo27px',
  'Slackey',
  'Smokum',
  'Smooch',
  'SmoochSans',
  'Smythe',
  'Sniglet',
  'Snippet',
  'SnowburstOne',
  'SofadiOne',
  'Sofia',
  'Solway',
  'SongMyung',
  'SonsieOne',
  'Sora',
  'SortsMillGoudy',
  'SourceCodePro',
  'SourceSans3',
  'SourceSansPro',
  'SourceSerif4',
  'SourceSerifPro',
  'SpaceGrotesk',
  'SpaceMono',
  'Spartan',
  'SpecialElite',
  'Spectral',
  'SpectralSC',
  'SpicyRice',
  'Spinnaker',
  'Spirax',
  'SplineSans',
  'SquadaOne',
  'SreeKrushnadevaraya',
  'Sriracha',
  'Srisakdi',
  'Staatliches',
  'Stalemate',
  'StalinistOne',
  'StardosStencil',
  'Stick',
  'StickNoBills',
  'StintUltraCondensed',
  'StintUltraExpanded',
  'STIXTwoMath',
  'STIXTwoText',
  'Stoke',
  'Strait',
  'StyleScript',
  'Stylish',
  'SueEllenFrancisco',
  'SuezOne',
  'SulphurPoint',
  'Sumana',
  'Sunshiney',
  'SupermercadoOne',
  'Sura',
  'Suranna',
  'Suravaram',
  'Suwannaphum',
  'SwankyandMooMoo',
  'Syncopate',
  'Syne',
  'SyneMono',
  'SyneTactile',
  'Tajawal',
  'Tangerine',
  'Taprom',
  'Tauri',
  'Taviraj',
  'Teko',
  'Telex',
  'TenaliRamakrishna',
  'TenorSans',
  'TextMeOne',
  'Texturina',
  'Thasadith',
  'TheGirlNextDoor',
  'TheNautigal',
  'Tienne',
  'Tillana',
  'Timmana',
  'Tinos',
  'TitanOne',
  'TitilliumWeb',
  'Tomorrow',
  'Tourney',
  'TradeWinds',
  'TrainOne',
  'Trirong',
  'Trispace',
  'Trocchi',
  'Trochut',
  'Truculenta',
  'Trykker',
  'TulpenOne',
  'TurretRoad',
  'TwinkleStar',
  'Ubuntu',
  'UbuntuCondensed',
  'UbuntuMono',
  'Uchen',
  'Ultra',
  'UncialAntiqua',
  'Underdog',
  'UnicaOne',
  'UnifrakturMaguntia',
  'Unkempt',
  'Unlock',
  'Unna',
  'Urbanist',
  'VampiroOne',
  'Varela',
  'VarelaRound',
  'Varta',
  'VastShadow',
  'Vazirmatn',
  'VesperLibre',
  'ViaodaLibre',
  'Vibes',
  'Vibur',
  'Vidaloka',
  'Viga',
  'Voces',
  'Volkhov',
  'Vollkorn',
  'VollkornSC',
  'Voltaire',
  'VT323',
  'VujahdayScript',
  'WaitingfortheSunrise',
  'Wallpoet',
  'WalterTurncoat',
  'Warnes',
  'Waterfall',
  'Wellfleet',
  'WendyOne',
  'WindSong',
  'WireOne',
  'WorkSans',
  'XanhMono',
  'Yaldevi',
  'YanoneKaffeesatz',
  'Yantramanav',
  'YatraOne',
  'Yellowtail',
  'YeonSung',
  'YesevaOne',
  'Yesteryear',
  'Yomogi',
  'Yrsa',
  'YujiBoku',
  'YujiHentaiganaAkari',
  'YujiHentaiganaAkebono',
  'YujiMai',
  'YujiSyuku',
  'YuseiMagic',
  'ZCOOLKuaiLe',
  'ZCOOLQingKeHuangYou',
  'ZCOOLXiaoWei',
  'ZenAntique',
  'ZenAntiqueSoft',
  'ZenDots',
  'ZenKakuGothicAntique',
  'ZenKakuGothicNew',
  'ZenKurenaido',
  'ZenLoop',
  'ZenMaruGothic',
  'ZenOldMincho',
  'ZenTokyoZoo',
  'Zeyada',
  'ZhiMangXing',
  'ZillaSlab',
  'ZillaSlabHighlight',
];
