
import Vue from 'vue';
import { eModeType } from '@/enums';
import { defaultThemes, DEFAULT_MODE_THEME } from '@/constants';
import SitchThemePreview from '@/components/custom-ui-components/SitchThemePreview.vue';
import SitchFontPreview from '@/components/custom-ui-components/SitchFontPreview.vue';
import { t } from '@/util-functions/language-utils';
import { Timestamp } from 'firebase/firestore';
import { updateUserModeGatewayDoc } from '@/util-functions/user-utils';
import { showSuccess } from '@/util-functions/notice-utils';
import { showError } from '@/util-functions/misc-firestore-utils';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modeType: {
      type: String,
      default: '',
    },
  },
  components: {
    SitchThemePreview,
    SitchFontPreview,
  },
  data(): {
    showModal: boolean;
    textValue: string;
  } {
    return {
      showModal: false,
      textValue: this.value === DEFAULT_MODE_THEME ? defaultThemes[DEFAULT_MODE_THEME].name : t.selectTheme,
    };
  },
  computed: {
    hasAssignedTeamTheme(): boolean {
      const publicUserModeGateway: PublicUserModeGateway = this.$store.state.currUserModeGateway;
      return Boolean(publicUserModeGateway.assignedTeamThemeId);
    },
    isOnThemesPage(): boolean {
      return window.location.pathname.includes('/Themes') || window.location.pathname.includes('/ThemeForm');
    },
    themeData(): AestheticTheme | null {
      return this.themes.find((theme) => theme.id === this.value) || null;
    },
    themes(): AestheticTheme[] {
      const ret = [...this.$store.getters.themes];
      if (this.modeType === eModeType.site) {
        const siteModeOption = {
          name: t.useModeThemePerPage,
          id: '',
        };
        ret.push(siteModeOption);
      }
      return ret;
    },
  },
  mounted() {
    this.onLoad();
  },
  watch: {
    themeData() {
      this.onLoad();
    },
  },
  methods: {
    onLoad() {
      this.textValue = this.themeData ? this.themeData.name : t.selectTheme;
    },
    onThemeSelect(theme: AestheticTheme) {
      this.textValue = theme.name;
      this.$emit('input', theme.id);
      this.$emit('change', theme.id);
    },
    optOutOfTeamTheme() {
      updateUserModeGatewayDoc({
        teamOwnerGatewayRef: null,
        assignedTeamThemeId: '',
        dateUpdated: Timestamp.fromMillis(Date.now()),
      })
        .then(() => {
          showSuccess(t.updateSuccessful);
        })
        .catch((error: any) => {
          showError(`Could not opt out of your team theme.`, error, true);
        });
    },
  },
});
